/* Form.module.css */

.divForm {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  min-width: 600px;
  margin: 80px auto;
  min-height: 500px;
}

.info {
  margin-top: 20px;
}

.subDiv {
  display: flex;
  flex-direction: column; 

}

.errors{
  color: red;
  font-size: 12px;
}

.divButton {
  margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.divButton .button {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
  color: #15214a;
  border: 1px solid #15214a;
  background-color: transparent;
  transition: color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.divButton .button::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  border-radius: 50px;
  background-color: #15214a;
  z-index: -1;
  transition: left 0.3s ease-in-out;
}

.divButton .button:hover {
  color: white;
}

.divButton .button:hover::before {
  left: 0;
}

.button[disabled] {
  font-size: 15px;
  background-color: #999;
  cursor: not-allowed;
  color:#ccc;
  padding: 10px 30px;
  font-weight: bold;
  border: 1px solid #999;

}

/* Estilos para los títulos de cada paso */
.h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #15214a;
}

/* Estilos para los componentes de los pasos */
/* Puedes personalizar estos estilos según tus necesidades */

/* Estilos para el componente Datas */
.divDatas {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  min-height: 200px; /* Establece un alto mínimo */
}

/* Estilos para los campos de entrada de texto */
.datas[type="text"]{
  width: 80%; /* Ocupa todo el ancho disponible */
  padding: 10px;
  margin: 15px 15px 15px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.datas[type="number"] {
  width: 100%; /* Ocupa todo el ancho disponible */
  padding: 10px;
  margin: 15px 15px 15px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Estilos para el placeholder de los campos de entrada */
.datas::placeholder {
  color: #999;
}

/* Estilos para el componente Shipment */
.divShipment {
  font-size: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  min-height: 200px; /* Establece un alto mínimo */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Estilos para los campos de entrada de texto */
.shipment[type="text"] {
  width: 100%; /* Ocupa todo el ancho disponible */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px;
}

/* Estilos para el placeholder de los campos de entrada */
.shipment::placeholder {
  color: #999;
}

/* Estilos para los input de radio y sus etiquetas */
.label {
  font-size: 15px;
  display: block;
  width: 90%;
  cursor: pointer;
}

.labelsec{
  font-size: 15px;
  display: block;
  width: 90%;
  cursor: pointer;
  margin: 10px;
}

/* Estilos para el componente Pay */
.pay {

  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  min-height: 200px;
  display: flex; /* Utiliza flexbox */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  flex-direction: column;
}

.btn{
  width: 300px;
  height: 40px;
  color: white;
  border-radius: 10px;
  border: 1px solid #3cbbed;
  cursor: pointer;
  font-size: 20px;
  background-color: #3cbbed;
  margin: 10px;
}

.btn:active {
  background-color: #3cbbed; /* Cambio de color de fondo cuando el botón está activo (clic) */
  color: white; /* Cambio de color de texto para mejorar la legibilidad */
}

.btn[disabled]{
  background-color: #999;
  color: #ccc;
  border: #999;
}