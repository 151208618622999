


/* Div contenedor seccion completa */
.contenedor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

}

/* Titulo  */
.titulo {
    font-family: "Gotham", sans-serif;
    color: #15214a;
}

/* Div de botones */
.divbotones {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5%;

    height: 100%
}

.section {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 1.5%;
}
/* Boton en off */
.offBoton {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #3cbbed;
    background-color: transparent;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.offBoton:hover{
    color: white;
}
.logoboton {
    width: 15px;
    height: 15px;
}
.offBoton:hover .logoboton {
    filter: brightness(50); /* Cambia la imagen a escala de grises al hacer hover en offBoton */
    cursor: pointer; /* Cambia el cursor al hacer hover en offBoton */
  }
.offBoton::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #98d0e6;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.offBoton:hover::before{
    width: 100%;
    opacity: 1;
}

/* Boton en on  */
.onBoton {
    border: none;
    font-size: 15px;
    padding: 8px 28px 8px 28px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    color: white;
    transition: 0.2s;
    border: 1px solid #3cbbed;
    background-color: #3cbbed;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
}


/* Div profile */
.profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20%;
    background-color: #15214a;

}

.inicial {
    border-radius: 50px;
    background: #3cbbed;
    display: flex;
    font-size: 15px;
    font-family: "Gotham", sans-serif;
    color: white;
    width: 20px;
    height: 20px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}


.barralateral {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100vb;
}

.dataProfile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1px;
    padding: 5px;
}

.nombre {
    font-size: 15px;
    font-family: "Gotham", sans-serif;
    color: white;
    font-weight: bold;
    margin-bottom: 1%;
}
.correo {
    font-size: 12.5px;
    font-family: "Gotham", sans-serif;
    color: white;
    font-weight: lighter;
    margin-top: 0px;
}



.offBoton2 {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #3cbbed;
    background-color: transparent;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.offBoton2:hover{
    color: white;
    background-color: rgb(236, 116, 116);
}
.logoboton {
    width: 15px;
    height: 15px;
}
.offBoton2:hover .logoboton {
    filter: brightness(50); /* Cambia la imagen a escala de grises al hacer hover en offBoton */
    cursor: pointer; /* Cambia el cursor al hacer hover en offBoton */
  }
  .offBoton2::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #98d0e6;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.offBoton2:hover::before{
    width: 100%;
    opacity: 1;
}