.div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items:center;
    justify-content: center;
    gap: 5px;
}

.logo {
    margin-top: 10px;
    width: 200px;
}

.titulo1 {
    border: none;
    font-size: 18px;
    padding: 8px 28px 8px 28px;
    border-radius: 50px;
    position: relative;
    color: white;
    transition: 0.2s;
    background-color: #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    width: auto;
}
.titulo2 {
    border: none;
    font-size: 15px;
    padding: 8px 28px 8px 28px;
    border-radius: 50px;
    position: relative;
    color: #15214a;
    transition: 0.2s;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    width: auto;
}

.divCampo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    .label {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-size: 12.5px;
        font-weight: bolder;
        padding-left: 5px;
    }
}

.divInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 1px;
    .p {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-weight: bold;
        font-size: 15px;
        margin-top: 1px;
    }
    .input {
        width: 400px;
        height: auto;
        padding: 4px;
        font-family: "Gotham", sans-serif;
        font-size: 18px;
        align-items: center;
        border-radius: 10px;
        background: transparent;
        border: 0.5px solid #3cbbed;
        text-align: center;
    }
    .input::placeholder {
        font-size: 12.5px;
    }
    .input:focus {
        outline: none;
        border: 2px solid #3cbbed;
    }
    .select {
        font-family: "Gotham", sans-serif;
        background-color: white;
        width: 400px;
        height: auto;
        padding: 4px;
        color: #2f92b9;
        text-align: center;
        font-size: 15px;
        border: solid #3cbbed 1.8px;
        border-radius: 10px;
    }
    .select:focus {
        background-color: #3cbbed;
        color: white;
        outline: none; 
        border-color: #15214a;
    }
}





.mensaje {
    display: flex;
    flex-direction: column;
    align-items: center;
    .positivo {
        font-family: "Gotham", sans-serif;
        color: white;
        background-color: rgb(74, 134, 74);
        padding: 5px;
        border-radius: 10px;
        align-items: center;
        align-content: center;
    
    }
}


.errorMenssage {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    padding: 0px;
    text-align: left;
}
.simbolo {
    font-family: "Gotham", sans-serif;
        color: red;
        font-weight: bolder;
        margin: 0px;
        padding: 0px;
        font-size: 11.5px;
}

.errorText {
    font-size: 11.5px;
    margin: 0px;
    transition: opacity 0.3s ease;
    font-family: "Gotham", sans-serif;
    color: red;
    
}


.formbutton {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}
.formbutton:hover{
    color: white;
}
.formbutton::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #15214a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.formbutton:hover::before{
    width: 100%;
    opacity: 1;
}

.errorMenssagePass {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
}