.divContenedorHab {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    justify-items: center;

}

.divProductImage {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    gap: 10px;
    margin: 0px;
    width: 50%;
    height: auto;
    border: 2px solid #15214a;
    border-radius: 50px;
}


.productImage {
    width: 250px;
}

.divContHabi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    justify-items: center;
    text-align: center;
    padding: 10px;
}

.titleProductEdit {
    border: none;
    font-size: 20px;
    padding: 8px 28px 8px 28px;
    color: #15214a;
    font-family: "Gotham", sans-serif;
    width: auto;
    font-weight: bold;
    margin: 0px;
}

.mensajeHabilitarP {
    margin: 0%;
    font-family: "Gotham", sans-serif;
    font-size: 13.5px;
    color: #1d4ef0;
    font-weight: bold;
}
.mensajeStock {
    margin: 0%;
    font-family: "Gotham", sans-serif;
    font-size: 13.5px;
    color: #1d4ef0;
}


.select {
    font-family: "Gotham", sans-serif;
    background-color: white;
    width: 400px;
    height: auto;
    padding: 4px;
    color: #3cbbed;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    border: solid #3cbbed 1.8px;
    border-radius: 10px;
}
.select:focus {
    background-color: #3cbbed;
    color: white;
    outline: none; 
    border-color: #15214a;
}


.saveBoton {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #3cbbed;
    border: 2px solid #3cbbed;
    background-color: transparent;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.saveBoton:hover{
    color: white;
}
.saveBoton::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #3cbbed;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.saveBoton:hover::before{
    width: 100%;
    opacity: 1;
}

.mensajeCreated {
    font-family: "Gotham", sans-serif;
    color: white;
    background-color: rgb(74, 134, 74);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;
    margin: 0px;

}