.divMain{
    display: flex;
    flex-direction: column;

}

.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 105px;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.logo{
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seguro{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    gap: 10px;
}
.title{
    padding-left: 50px;
}

.contenedor{
    display: flex;
    justify-content: center;
    gap: 200px;
}




            .divDetalle{
                color: #2C2D32;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                width: 350px;
                background-color: lightblue;
                margin: 0px 10px;
                padding: 10px;
                align-items: center;
                height: auto;
            }

            

            
            .ticket{
                display: flex;
                justify-content: space-between;
            }

.img{
    background-color: #3cbbed3b;
    border-radius: 5px;
}

.divSpan{
    color: #2C2D32;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 60%;
    justify-content: space-around;
}

.span1{
    font-size: 17px;
    display: inline-block; /* Asegura que el span tenga un ancho específico */
    width: 150px; /* Establece el ancho máximo que deseas */
    white-space: nowrap; /* Evita el salto de línea del texto */
    overflow: hidden; /* Oculta el contenido que no cabe */
    text-overflow: ellipsis;
}
.span2{
    font-size: 13px;
    color: #656569;
}

.span3{
    font-size: 14px;
}

.separador{
    background-color: #2C2D32;
    width: 80%;
    height: 2px;
    border-radius: 20px;
    margin: 17px auto;

}

.divTotal{
    display: flex;
    align-items: center;
    width: 250px;
    justify-content: space-between;
}