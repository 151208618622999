.div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.tituloEdit {
    border: none;
    font-size: 17.5px;
    padding: 8px 28px 8px 28px;
    position: relative;
    color: #15214a;
    transition: 0.2s;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    width: auto;
    margin: 3px;
    font-weight: light;
}

.form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    gap: 10px;
}

.divCampo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    .label {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-size: 13px;
        font-weight: bolder;
        padding-left: 5px;
    }
}

.divInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 1px;
    .p {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-weight: bold;
        font-size: 12px;
        margin-top: 1px;
    }
    .input {
        width: 400px;
        height: auto;
        padding: 4px;
        font-family: "Gotham", sans-serif;
        font-size: 15px;
        align-items: center;
        border-radius: 10px;
        background: transparent;
        border: 0.5px solid #3cbbed;
        text-align: center;
        font-weight: bold;
    }
    .input::placeholder {
        font-size: 12.5px;
    }
    .input:focus {
        outline: none;
        border: 2px solid #3cbbed;
    }
    .select {
        font-family: "Gotham", sans-serif;
        background-color: white;
        width: 400px;
        height: auto;
        padding: 4px;
        color: #3cbbed;
        text-align: center;
        font-size: 15px;
        border: solid #3cbbed 1.8px;
        border-radius: 10px;
    }
    .select:focus {
        background-color: #3cbbed;
        color: white;
        outline: none; 
        border-color: #15214a;
    }
}


.select {
    font-family: "Gotham", sans-serif;
    background-color: white;
    width: 400px;
    height: auto;
    padding: 4px;
    color: #3cbbed;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    border: solid #3cbbed 1.8px;
    border-radius: 10px;
}
.select:focus {
    background-color: #3cbbed;
    color: white;
    outline: none; 
    border-color: #15214a;
}


.inputext {
    width: 400px;
    height: auto;
    padding: 4px;
    font-family: "Gotham", sans-serif;
    font-size: 15px;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: transparent;
    border: 0.5px solid #3cbbed;
    text-align: center;
    font-weight: bold;
}
.inputext::placeholder {
    font-size: 12.5px;
}
.inputext:focus {
    outline: none;
    border: 2px solid #3cbbed;
}


.divVistaPrevia {
    width: 400px;
    border: 2px solid #15214a;
    height: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
}

.divImagenes {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.miniatura {
    width: 100px;
    height: 100px;
    padding: 5px;
}


.mensajeProducto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    justify-items: center;
    text-align: center;
    padding: 10px;
}
.mensajeCreated {
    font-family: "Gotham", sans-serif;
    color: white;
    background-color: rgb(74, 134, 74);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;

}
.mensajeNoCreated {
    font-family: "Gotham", sans-serif;
    color: white;
    background-color: rgb(134, 74, 74);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;

}

.formbutton {
    border: none;
    font-size: 18px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #3cbbed;
    background-color: transparent;
    border: 2px solid #3cbbed;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}
.formbutton:hover{
    color: white;
}
.formbutton::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #3cbbed;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.formbutton:hover::before{
    width: 100%;
    opacity: 1;
}


.formbuttonClean {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}
.formbuttonClean:hover{
    color: white;
}
.formbuttonClean::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #15214a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.formbuttonClean:hover::before{
    width: 100%;
    opacity: 1;
}

.aviso {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.botonYes  {
    border: none;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}
.botonYes:hover{
    color: white;
}
.botonYes::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #2954e2;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.botonYes:hover::before{
    width: 100%;
    opacity: 1;
}

.botonNo  {
    border: none;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}
.botonNo:hover{
    color: white;
}
.botonNo::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #01165a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}
.botonNo:hover::before{
    width: 100%;
    opacity: 1;
}

.mensajeAviso {
    font-size: 13.5px;
    font-family: "Gotham", sans-serif;
}


.errorMenssage {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    padding: 0px;
    text-align: left;
    margin: 0px;
}
.simbolo {
    font-family: "Gotham", sans-serif;
        color: red;
        font-weight: bolder;
        cursor: pointer;
        margin: 0px;
        padding: 0px;
}

.errorText {
    font-size: 12px;
    margin: 0px;
    transition: opacity 0.3s ease;
    font-family: "Gotham", sans-serif;
    color: red;
    
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}